




















































































import { Component, Vue, Watch } from "vue-property-decorator";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import { UsernameAndPassword } from "@/interfaces/UserInterface";
import { toFieldError } from "@/helpers/ErrorHandler";

@Component({
    components: {
        BaseWrapper,
    },
})
export default class AppRegistration extends Vue {
    public input: UsernameAndPassword = {
        username: "",
        password: "",
    };
    public fieldError: any = {};
    public formKey: number = 0;
    public showPage: boolean = false;
    public disableButton: boolean = false;

    get me(): any {
        return this.$store.state.users.me;
    }

    get roles() {
        return this.$store.state.roles.roles;
    }

    get proposerRole() {
        let proposerRole: any = null;

        if (this.roles && this.roles.length) {
            proposerRole = this.roles.filter((role: any) => {
                return role.name === "PROPOSER";
            })[0];
        }

        return proposerRole;
    }

    async signUpWithUsernameAndPassword() {
        this.fieldError = {};
        this.disableButton = true;

        const body: any = {
            ...this.input,
            role: this.proposerRole,
        };
        const errors = await this.$store.dispatch(
            "users/signUpWithUsernameAndPassword",
            body
        );

        setTimeout(() => {
            this.fieldError = toFieldError(errors);
            this.formKey++;
            this.disableButton = false;
        }, 500);
    }

    @Watch("me", { immediate: true })
    redirectToApp(me: any) {
        const isLoggedIn = !!me;

        if (isLoggedIn) this.$router.push("/app");
        else this.showPage = true;
        // this.showPage = true;
    }

    async mounted() {
        const roles = await this.$store.dispatch("roles/getAllRole");
    }
}
